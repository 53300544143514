<template>
  <div class="main-content">
    <avue-form ref="form" :value="form" :option="formOption">
      <template slot="video">
        <div>
          <FormVideoUpload
            :url="form.video"
            @upload="uploadVideo"
            :limit="1"
            :maxSize="500"
            :accept="'.mp4,.webm,.avi'"
            :multiple="false"
          />
          <span style="color: #c0c1c3">
            最大500M，视频格式：.mp4,.webm,.avi
          </span>
        </div>
      </template>
      <template slot="menuForm">
        <!-- <el-button @click="handleClose">取消</el-button> -->
        <div
          style="
            display: flex;
            justify-content: flex-start;
            margin-left: 9%;
            margin-top: 30px;
          "
        >
          <el-button type="primary" :loading="showLoading" @click="submit(form)"
            >确定</el-button
          >
        </div>
      </template>
    </avue-form>
  </div>
</template>

<script>
import FormImgUpload from "@/components/formComponents/formImgUpload";
import { basicUpdate, basicView } from "@/api/system/basic";
import { enterChineseEnglish } from "@/utils/formRules";
import { newsVideoView, newsVideoUpdate } from "@/api/news/video";
import { emailRegex, telephoneRule } from "@/utils/formRules";
import FormVideoUpload from "@/components/formComponents/formVideoUpload";
export default {
  name: "createThree",
  components: {
    FormVideoUpload,
  },
  data() {
    return {
      form: {
        video: [],
      },
      showLoading: false,
      formOption: {
        submitBtn: false,
        emptyBtn: false,
        labelWidth: 150,
        column: [
          {
            label: "首页视频",
            prop: "video",
            type: "text",
            maxlength: 50,
            span: 24,
            rules: [
              {
                required: true,
                message: "请上传",
                trigger: "blur",
              },
              {
                validator: (rule, value, callback) => {
                  if (value.length === 0) {
                    callback(new Error("请上传"));
                  } else {
                    callback();
                  }
                },
              },
            ],
          },
        ],
      },
    };
  },
  created() {
    this.onLoad();
  },
  methods: {
    //初始化
    onLoad() {
      this.showLoading = true;
      newsVideoView().then((res) => {
        this.showLoading = false;
        if (res.code === 200) {
          this.form = {
            ...res.data,
            video: res.data.video.split(","),
          };
        }
      });
    },
    //视频上传
    uploadVideo(e, name, size, duration, index) {
      if (Array.isArray(e)) {
        this.form.video = [...e];
      } else {
        this.form.video = [e];
      }
    },
    submit(form) {
      this.$refs.form.$refs.form.validate((valid, done) => {
        if (valid) {
          const res = {
            ...form,
            video: form.video.join(","),
          };
          this.showLoading = true;
          newsVideoUpdate({ ...res })
            .then((res) => {
              if (res.code === 200) {
                this.$message.success("操作成功");
                this.dialogVisible = false;
                this.onLoad();
                this.showLoading = false;
              } else {
                // this.$message.error(res.msg);
                this.showLoading = false;
                done();
              }
            })
            .catch(() => {
              this.showLoading = false;
            });
          // done()
        }
      });
    },
  },
};
</script>

<style scoped></style>
